@charset "utf-8";

.privacy {
  .spot {
    background-color: #eaeff3;

    .grid {
      background-image: url(../../img/img_spot_policy.jpg);
    }

    &__title {
      display: inline-block;
      @include sp-pc('icon_policy_title');
    }
  }

  &__intro {
    margin-top: 30px;
  }

  &__version {
    font-weight: bold;
    font-size: 15px;
    color: $color-dark-default;
  }

  &__disc {
    font-size: 13px;
    line-height: 20px;
  }

  &__list {
    margin-top: 10px;
    counter-reset: section;
  }

  &__item {
    position: relative;
    padding-left: 25px;
    color: $color-dark-default;
    line-height: 23px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 13px;
      counter-increment: section;
      content: counter(section) ".";
    }
  }

  &__link {
    font-size: 13px;
    line-height: 20px;
  }

  &__contents {
    margin-top: 30px;
  }

  &__title {
    font-weight: bold;
    font-size: 15px;
    color: $color-dark-default;

    &+ul>li {
      margin-top: 10px;
    }
  }

  &__btn {
    display: inline-block;
    position: relative;
    margin-top: 12px;
    font-size: 14px;
    color: $color-dark-default;
    letter-spacing: -0.4px;

    &::after {
      display: inline-block;
      margin-left: 5px;
      @include sp-pc('icon_next_small_blue');
      vertical-align: 1px;
      content: '';
    }
  }

  &-unit {
    margin-top: 30px;
    font-size: 13px;
    line-height: 20px;

    &:first-child {
      margin-top: 0;
    }

    &__title {
      font-weight: bold;

      &+.privacy-unit__list {
        margin-top: 0;
      }
    }

    &__disc {
      &+ul li {
        margin-top: 10px;
      }
    }

    &__list {
      margin-top: 10px;

      &+.privacy-unit__disc {
        margin-top: 10px;
      }
    }

    &__item {
      position: relative;
      margin-top: 10px;
      padding-left: 18px;

      &--nomargin {
        margin-top: 0 !important;
      }

      &::before {
        position: absolute;
        top: 9px;
        left: 0;
        width: 4px;
        height: 2px;
        background-color: $color-black;
        content: '';
      }
    }

    table {
      width: 100%;
      margin-top: 10px;
      border-collapse: collapse;
      border-top: 2px solid #898989;
      border-bottom: 1px solid #898989;
      font-size: 13px;
      text-align: center;
    }

    th {
      height: 50px;
      border-left: 1px solid #898989;
      font-weight: bold;
      line-height: 50px;

      &:first-child {
        border-left: none;
      }
    }

    td {
      height: 68px;
      border-top: 1px solid #898989;
      border-left: 1px solid #898989;

      &:first-child {
        border-left: none;
      }
    }

    &__box {
      margin-top: 20px;
      padding: 38px 90px;
      border: 2px solid #f0f0f0;

      &+.privacy-unit__disc {
        margin-top: 20px;
      }
    }

    &__info {
      margin-top: 0 !important;
      font-size: 0;

      &-title {
        margin-right: 13px;
        font-weight: bold;
        font-size: 13px;
        color: $color-dark-default;
      }

      &-list {
        display: inline-block;
        vertical-align: top;
      }

      &-item {
        display: inline-block;
        position: relative;
        margin-top: 0 !important;
        padding: 0 9px 0 11px;
        font-size: 13px;

        &::before {
          position: absolute;
          top: 4px;
          left: 0;
          width: 1px;
          height: 12px;
          background-color: #e5e5e5;
          content: '';
        }
      }
    }
  }

  // 개인정보 처리방침 팝업
  &__img {
    padding: 50px 0 30px;
  }

  &__tab {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    background-color: #fafafa;
    font-size: 0;

      &-link {
        display: inline-block;
        margin: 0 15px;
        padding: 0 10px;
        font-weight: bold;
        font-size: 14px;
        color: $color-black;
        line-height: 59px;
      
      &:hover {
        color: $color-light-default;
      }

      &[aria-selected="true"] {
        color: $color-dark-default;
      }
    }

    & + .content .privacy__intro {
      margin-top: 0;
    }
  }
}
