@charset "utf-8";

.site-map {
  .location__list {
    padding: 83px 0 20px;
  }

  .content__title {
    font-size: 30px;
  }

  &__list {
    margin-top: 27px;
    font-size: 0;
  }

  &__item {
    display: inline-block;
    width: 240px;
    height: 343px;
    margin-left: 20px;
    background-color: #f7f7f7;
    border-top: 2px solid $color-dark-default;

    &:first-child {
      margin-left: 0;
    }
  }

  .category {
    &__title {
      display: block;
      height: 55px;
      padding: 0 30px;
      border-bottom: 1px solid rgba(14, 93, 178, 0.15);
      font-weight: bold;
      font-size: 16px;
      color: $color-dark-default;
      line-height: 55px;
    }

    &__list {
      padding: 9px 30px;
    }

    &__link {
      font-weight: bold;
      font-size: 14px;
      color: #525252;
      line-height: 32px;
    }
  }
}
