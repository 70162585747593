$pc-vars: (
	'icon_arrow_black': (
		offset-x: -39px,
		offset-y: -137px,
		width: 5px,
		height: 9px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_arrow_circle': (
		offset-x: -189px,
		offset-y: -114px,
		width: 12px,
		height: 12px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_arrow_circle_gray': (
		offset-x: -172px,
		offset-y: -114px,
		width: 13px,
		height: 13px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_arrow_down': (
		offset-x: -233px,
		offset-y: -114px,
		width: 9px,
		height: 5px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_arrow_down_ge': (
		offset-x: -205px,
		offset-y: -114px,
		width: 10px,
		height: 6px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_arrow_step': (
		offset-x: 0px,
		offset-y: -173px,
		width: 18px,
		height: 35px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_arrow_step_small': (
		offset-x: -306px,
		offset-y: -93px,
		width: 8px,
		height: 12px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_arrow_up': (
		offset-x: -246px,
		offset-y: -114px,
		width: 9px,
		height: 5px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_arrow_up_ge': (
		offset-x: -219px,
		offset-y: -114px,
		width: 10px,
		height: 6px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_home': (
		offset-x: -285px,
		offset-y: -93px,
		width: 17px,
		height: 14px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_logo_affiliate': (
		offset-x: -88px,
		offset-y: -114px,
		width: 80px,
		height: 17px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_logo_blog': (
		offset-x: 0px,
		offset-y: -114px,
		width: 84px,
		height: 19px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_logo_footer': (
		offset-x: -151px,
		offset-y: -93px,
		width: 130px,
		height: 15px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_logo_header': (
		offset-x: 0px,
		offset-y: -93px,
		width: 147px,
		height: 17px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_next_small': (
		offset-x: -48px,
		offset-y: -137px,
		width: 3px,
		height: 6px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_next_small_blue': (
		offset-x: -55px,
		offset-y: -137px,
		width: 3px,
		height: 6px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_policy_title': (
		offset-x: 0px,
		offset-y: -62px,
		width: 203px,
		height: 27px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_quotes_left': (
		offset-x: -238px,
		offset-y: -31px,
		width: 25px,
		height: 22px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_quotes_left_medium': (
		offset-x: 0px,
		offset-y: -137px,
		width: 35px,
		height: 32px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_quotes_right': (
		offset-x: -267px,
		offset-y: -31px,
		width: 24px,
		height: 20px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_recruit_title': (
		offset-x: -207px,
		offset-y: -62px,
		width: 101px,
		height: 23px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_service_title': (
		offset-x: 0px,
		offset-y: -31px,
		width: 234px,
		height: 27px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_summary_title': (
		offset-x: 0px,
		offset-y: 0px,
		width: 314px,
		height: 27px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
	'icon_telephone': (
		offset-x: -295px,
		offset-y: -31px,
		width: 14px,
		height: 18px,
		total-width: 314px,
		total-height: 208px,
		imageSrc: '../img/pc.png'
	),
);
$pc-origin: (
  total-width: 314px,
  total-height: 208px,
  imageSrc: '../img/pc.png'
);

@mixin sprite-pc-size($image) {
	background-size: map-get($image, 'total-width') map-get($image, 'total-height');
}

@mixin sprite-pc-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-pc-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

@mixin set-pc {
  @include sprite-pc-size($pc-origin);
  @include sprite-pc-image($pc-origin);
}

@mixin sp-pc($image, $size: true) {
  @include set-pc;
	background-position: map-get(map-get($pc-vars, $image), 'offset-x') map-get(map-get($pc-vars, $image), 'offset-y');
	@if $size {
		width: map-get(map-get($pc-vars, $image), 'width');
		height: map-get(map-get($pc-vars, $image), 'height');
	}
}
