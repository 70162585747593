@charset "utf-8";

.service {
  .spot {
    background-color: #eaeff3;

    .grid {
      background-image: url(../../img/img_spot_service.jpg);
    }

    &__title {
      display: inline-block;
      @include sp-pc('icon_service_title');
    }
  }

  &__img {
    &-wrap {
      margin-top: 50px;
      padding: 9px 0 11px;
      border-bottom: 1px solid #e5e5e5;
      text-align: right;
    }

    img {
      width: 100%;
    }
  }

  &__cta {
    display: inline-block;
    position: relative;
    margin-top: 16px;
    font-size: 14px;
    color: $color-dark-default;
    letter-spacing: -0.4px;

    &::after {
      display: inline-block;
      margin-left: 9px;
      @include sp-pc('icon_arrow_circle');
      vertical-align: -2px;
      content: '';
    }
  }

  &__inner {
    margin-top: 60px;
    padding: 0 62px;
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    color: $color-dark-default;
    line-height: 1.44;

    &+.service__disc {
      margin-top: 20px;
    }
  }

  &__disc {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.79;
  }

  &__list {
    margin: 20px 0 0 20px;
  }

  &__item {
    position: relative;
    margin-top: 22px;
    padding-left: 18px;
    font-size: 14px;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      position: absolute;
      top: 6px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $color-black;
      content: '';
    }
  }
}
