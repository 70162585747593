@charset "utf-8";

.footer {
  width: 100%;
  padding: 27px 0;
  background-color: #494949;

  &__information {
    display: inline-block;
    margin-left: 27px;
    font-size: 0;
  }

  &__logo-ico {
    display: inline-block;
    @include sp-pc('icon_logo_footer');
  }

  &__copyright {
    margin-top: 16px;
    font-size: 12px;
    color: #828282;
    line-height: 1.5;
    letter-spacing: -0.6px;

    strong {
      color: #1e89fc;
    }
  }

  &__list::after {
    @include clear;
  }

  &__item {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: -0.6px;

    &:first-child {
      float: left;
      position: relative;
      margin-right: 14px;

      &::after {
        content: '|';
        display: block;
        position: absolute;
        right: -8px;
        top: 0;
        color: #828282;
      }
    }

    dl {
      &::after {
        @include clear;
      }
    }

    dt,
    dd {
      float: left;
      color: #828282;
    }
  }

  &__contents {
    float: right;
    margin-right: 57px;
    font-size: 0;
  }

  .dropdown {
    position: relative;

    &__opener {
      position: relative;
      min-width: 184px;
      height: 30px;
      padding: 0 12px;
      border: 1px solid #6f6f6f;
      border-radius: 3px;
      font-weight: bold;
      font-size: 13px;
      color: $color-white;
      line-height: 30px;
      letter-spacing: -0.7px;
      text-align: left;

      &::after {
        display: block;
        position: absolute;
        top: 12px;
        right: 9px;
        @include sp-pc('icon_arrow_down_ge');
        content: '';
      }

      &[aria-expanded="true"] {
        border-radius: 0 0 3px 3px;
        color: #838383;

        &::after {
          top: 11px;
          @include sp-pc('icon_arrow_up_ge');
        }

        &~.dropdown__list {
          display: block;
        }
      }
    }

    &__list {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 100%;
      padding: 4px 0;
      border: 1px solid #6f6f6f;
      border-bottom: 0;
      border-radius: 3px 3px 0 0;
      background-color: #494949;
      font-size: 13px;
    }

    &__link {
      display: block;
      height: 20px;
      padding: 0 12px;
      font-weight: bold;
      color: #838383;
      line-height: 20px;
      letter-spacing: -0.7px;

      &:hover {
        color: $color-white;
      }
    }
  }

  &__cta {
    margin-top: 27px;
    font-size: 0;

    &-item {
      display: inline-block;

      &:last-child {
        margin-left: 20px;
      }
    }

    &-link {
      display: inline-block;

      &--affiliate {
        @include sp-pc('icon_logo_affiliate');
      }

      &--blog {
        @include sp-pc('icon_logo_blog');
      }
    }
  }
}
