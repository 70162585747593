@charset "UTF-8";

@font-face {
  font-weight: normal;
  font-family: 'NanumGothic';
  src: local(NanumGothic);
  src: url("../font/NanumGothic.eot");
  src: url("../font/NanumGothic.woff") format("woff"), url("../font/NanumGothic.ttf") format("truetype");
}

@font-face {
  font-weight: bold;
  font-family: 'NanumGothic';
  src: local(NanumGothicBold);
  src: url("../font/NanumGothicBold.eot");
  src: url("../font/NanumGothicBold.woff") format("woff"), url("../font/NanumGothicBold.ttf") format("truetype");
}
