@charset "utf-8";
@use 'sass:math';

@function pxrem($px, $base: 12) {
  @return math.div(deUnit($px), $base) * 1rem;
}

@function deUnit($value) {
  @return math.div($value, ($value * 0 + 1));
}

//button reset
@mixin form_reset {
  background: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  cursor: pointer;
}

//box-sizing
@mixin boxsizing {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

//clear
@mixin clear {
  display: block;
  clear: both;
  content: '';
}

//2 line ellipsis
@mixin ellp2 {
  display: -webkit-box;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@mixin breakpoint($point) {
  @if $point==desktop {
    @media screen and (min-width: $screen-lg) {
      @content;
    }
  } @else if $point==tablet {
    @media screen and (max-width: ($screen-lg - 1)) {
      @content;
    }
  } @else if $point==mobile {
    @media screen and (max-width: $screen-sm) {
      @content;
    }
  } @else if $point==mobile-landscape {
    @media screen and (max-width: $screen-sm) and (orientation: landscape) {
      @content;
    }
  }
}
