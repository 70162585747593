@charset "utf-8";

.main-slide {
  position: relative;
  margin: 0 auto;
  background-color: #f0f0f0;

  &.sp-horizontal .sp-arrows {
    margin-top: -60px;
  }

  .sp-buttons {
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
    z-index: 10;
  }

  .sp-button {
    width: 16px;
    height: 16px;
    background-color: #c4c5cc;
    border: 0;

    &.sp-selected-button {
      background-color: $color-white;
      border: 5px solid #5a91cc;
    }
  }

  .sp-grab {
    cursor: default;
  }

  .sp-arrow {
    width: 56px;
    height: 120px;
    background-image: url("../img/sprite_slide_arrow.png");
    background-repeat: no-repeat;
    background-position: 0 0;

    &.sp-previous-arrow {
      left: 150px;
    }

    &.sp-next-arrow {
      right: 150px;
    }

    &::before,
    &::after {
      content: none;
    }
  }

  &.ie8 {

    &.sp-horizontal .sp-previous-arrow,
    &.sp-horizontal .sp-next-arrow {
      border: 0;
    }

    &.sp-horizontal .sp-next-arrow {
      background-position: -61px 0;
    }

    .sp-arrow {
      width: 56px;
      height: 120px;
    }
  }
}

.main {

  &__contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 100px 0 110px;
    font-size: 0;
  }

  &__unit {
    display: inline-block;
    position: relative;
    width: calc(100% / 3);
    height: 224px;
    margin-top: 30px;
    background-color: $color-white;
    vertical-align: top;

    &--img {
      max-width: 327px;

      img {
        width: 100%;
      }
    }

    &--text {
      padding: 10px 19px;
    }

    &--recruit {
      padding: 26px 19px 7px 32px;

      &::before {
        display: inline-block;
        position: absolute;
        top: 0;
        @include sp-pc('icon_quotes_left_medium');
        content: '';
      }

      .main__disc {
        margin-top: 16px;
        font-size: 17px;
        color: #363636;
        letter-spacing: -0.4px;
      }

      .main__cta {
        bottom: 40px;
        left: 32px;
      }
    }
  }

  &__title {
    display: block;
    font-weight: bold;
    font-size: 23px;
    color: #333;
    letter-spacing: -0.6px;
    word-break: keep-all;

    &--line {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: -21px;
        left: 0;
        width: 40px;
        height: 2px;
        background-color: #333;
      }
    }
  }

  &__disc {
    margin-top: 48px;
    font-size: 13px;
    color: #333;
    line-height: 20px;
    letter-spacing: -0.3px;
  }

  &__cta {
    position: absolute;
    left: 19px;
    bottom: 10px;
    font-size: 13px;
    color: #333;
    letter-spacing: -0.3px;

    &::after {
      display: inline-block;
      margin-left: 5px;
      @include sp-pc('icon_arrow_black');
      content: '';
    }
  }
}
