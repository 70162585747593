@charset "utf-8";

.grid {
  width: 1064px;
  margin: 0 auto;
  padding: 0 20px;
}

.tab {
  background-color: $color-white;

  &__btn {
    display: inline-block;
    position: relative;
    margin: 0 15px;
    padding: 0 10px;
    font-weight: bold;
    font-size: 14px;
    color: $color-light-default;
    line-height: 59px;

    &[aria-selected="true"]::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      background-color: $color-light-default;
    }

    &-wrap {
      height: 59px;
      font-size: 0;
      text-align: center;
    }
  }
}

.spot {
  .grid {
    position: relative;
    height: 315px;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &__title {
    position: absolute;
    left: 20px;
    top: 122px;
  }

  &__text {
    position: absolute;
    left: 20px;
    top: 172px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.56;
    letter-spacing: -0.4px;
  }
}

.location {
  &__list {
    padding: 24px 0 20px;
    border-bottom: 1px solid #e5e5e5;
    text-align: right;
    font-size: 0;
  }

  &__item {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    padding-right: 12px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--current {
      font-weight: bold;
    }

    &--home .location__text::before {
      display: inline-block;
      @include sp-pc('icon_home');
      content: '';
    }

    &::after {
      display: inline-block;
      position: absolute;
      top: 4px;
      right: 0;
      @include sp-pc('icon_next_small');
      content: '';
    }

    &:last-child::after {
      display: none;
    }
  }

  &__text {
    font-size: 12px;
    color: #343434;
  }
}

.content {
  padding: 30px 0 140px;

  &__title {
    font-weight: bold;
    font-size: 20px;
    color: $color-black;
  }

  &__explain {
    margin-top: 50px;
    font-size: 16px;
    line-height: 1.81;
    letter-spacing: -0.8px;
  }
}
