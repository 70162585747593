* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

body,
button,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
ol,
p,
select,
table,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
  color: #000;
  -webkit-text-size-adjust: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong, th {
  font-weight: normal;
}

body,
button,
input,
select,
table,
textarea {
  font-family: "NanumGothic", "나눔고딕", "AppleSDGothicNeo", sans-serif;
}

img {
  border: 0;
  vertical-align: top;
}

ol,
ul {
  list-style: none;
}

li {
  vertical-align: top;
  list-style: none;
}

textarea {
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  resize: none;
}

address,
em {
  font-style: normal;
}

input,
select {
  vertical-align: middle;
}

input[type=text]::-ms-clear {
  display: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.blind {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}