@charset "utf-8";

.company {
  .spot {
    background-color: #e5edf0;

    .grid {
      background-image: url(../../img/img_spot_company.jpg);
    }

    &__title {
      display: inline-block;
      @include sp-pc('icon_summary_title');
    }
  }

  .information {
    margin-top: 30px;
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: -0.7px;

    &__title {
      display: inline-block;
      width: 100px;
      margin-right: 55px;
      font-weight: bold;
      color: #034892;
    }

    &__text {
      display: inline-block;
    }

    &__link {
      display: inline-block;
      position: relative;
      margin: 30px 0 0;
      padding: 12px 23px 12px 20px;
      border: 1px solid #ddd;
      font-weight: bold;
      font-size: 14px;
      color: #575757;
      letter-spacing: -0.4px;

      &::before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 172px;
        @include sp-pc('icon_arrow_circle_gray');
        transform: translateY(-50%);
        content: '';
      }

      &::after {
        display: inline-block;
        margin-left: 38px;
        @include sp-pc('icon_logo_affiliate');
        vertical-align: middle;
        content: '';
      }
    }
  }

  .address {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    font-size: 0;

    &__box {
      display: inline-block;
      font-size: 16px;
      vertical-align: top;
    }

    &__title {
      display: block;
      font-weight: bold;
      color: $color-dark-default;
    }

    &__text {
      margin-top: 20px;
      line-height: 1.44;
    }

    &__tel {
      display: inline-block;
      position: relative;
      margin-top: 30px;

      &::before {
        display: inline-block;
        margin-right: 5px;
        @include sp-pc('icon_telephone');
        vertical-align: middle;
        content: '';
      }
    }

    &__map {
      width: 538px;
      height: 260px;
      border: 1px solid #ccc;

      &-wrap {
        display: inline-block;
        text-align: right;
      }

      &+.address__link {
        margin-right: 14px;
      }
    }

    &__link {
      display: inline-block;
      font-size: 12px;
      line-height: 1.92;
      letter-spacing: -0.4px;
    }
  }

  .slide__wrap {
    margin-top: 50px;
    padding: 55px 0 50px;
    background-color: #f7f7f7;

    .slide__text {
      position: relative;
      height: 16px;
      font-weight: bold;
      font-size: 14px;
      color: #363636;
      letter-spacing: -0.6px;
      text-align: center;

      strong {
        font-weight: bold;
        color: $color-dark-default;
      }

      &::before {
        display: inline-block;
        position: absolute;
        left: 167px;
        top: -14px;
        @include sp-pc('icon_quotes_left');
        content: '';
      }

      &::after {
        display: inline-block;
        position: absolute;
        right: 167px;
        top: -7px;
        @include sp-pc('icon_quotes_right');
        content: '';
      }
    }

    .slider-pro {
      margin-top: 39px;
    }
  }

  #slide {
    .sp-fade-arrows {
      opacity: 1;
    }

    .sp-grab {
      cursor: default;
    }

    .sp-previous-arrow, .sp-next-arrow {
      width: 28px;
      height: 50px;
      border: 0;
      background-image: url(../img/sprite_slide_arrow.png);
      background-size: 178px 175px;
      transform: rotate(0);

      &:before, &:after {
        display: none;
      }
    }

    .sp-previous-arrow {
      background-position: -36px -124px;

      &:hover {
        background-position: -115px -124px;
      }
    }

    .sp-next-arrow {
      background-position: 0 -124px;

      &:hover {
        background-position: -79px -124px;
      }
    }

    .sp-bottom-thumbnails {
      margin: 22px auto 0;
    }

    .sp-thumbnail-container {
      margin-left: 1px;
      margin-right: 1px;
      opacity: 0.4;
      filter: alpha(opacity=40);
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .sp-selected-thumbnail {
      position: relative;
      opacity: 1;
      filter: alpha(opacity=100);

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border: 2px solid #728685;
      }
    }

    .sp-thumbnail-arrows {
      top: 0;
      z-index: 3;
      margin-top: 0;
      opacity: 1;
    }

    .sp-thumbnail-arrow {
      display: block !important;
    }

    .sp-previous-thumbnail-arrow, .sp-next-thumbnail-arrow {
      width: 25px;
      height: 75px;
      border: 0;
      background-image: url(../img/sprite_slide_arrow.png);
      background-size: 178px 175px;

      &:before, &:after {
        display: none;
      }
    }

    .sp-previous-thumbnail-arrow {
      left: 0;
      background-position: -122px 0;
    }

    .sp-next-thumbnail-arrow {
      right: 0;
      background-position: -153px 0;
    }
  }
}
