@charset "utf-8";

.header {
  width: 100%;
  background-color: $color-dark-default;

  .grid {
    height: 97px;
    font-size: 0;
  }

  &__logo {
    display: inline-block;
    height: 100%;
    vertical-align: top;

    &-link {
      display: flex;
      align-items: center;
      height: 100%;
      
      &::before {
        display: inline-block;
        @include sp-pc('icon_logo_header');
        content: '';
      }
    }
  }

  &__navigation {
    display: inline-block;
    margin-left: 60px;
  }

  .panel {
    display: none;
    position: absolute;
    top: 97px;
    left: 0;
    right: 0;
    height: 272px;
    background-color: $color-white;
    z-index: $z-index-header-panel;
  }

  &__menu {
    font-size: 0;

    &-item {
      display: inline-block;

      &.policy .header__menu-link {
        color: #b3d8ff;
      }
    }

    &-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 97px;
      font-size: 17px;
      color: #7bbbff;

      &:hover {
        color: $color-white;
      }
    }
  }

  &__dropMenu {
    display: none;
    position: absolute;
    height: 272px;
    margin-top: 22px;
    text-align: center;
    z-index: $z-index-header-panel;

    &-item {
      display: block;
      width: 160px;
      font-weight: bold;
      font-size: 14px;
      color: $color-light-default;
      line-height: 2.14;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__lnb {
    display: inline-block;
    height: 100%;
    margin-left: 38px;
    font-size: 0;

    &-item {
      display: inline-flex;
      align-items: center;
      height: 100%;

      &:first-child {
        margin-left: 0;

        .header__lnb-link::after {
          content: '';
          display: block;
          position: absolute;
          top: 15px;
          right: 0;
          width: 1px;
          height: 9px;
          background-color: rgba(255, 255, 255, 0.5);
        }
      }

      &:last-child .header__lnb-link {
        padding-right: 0;
      }
    }

    &-link {
      display: block;
      position: relative;
      padding: 10px;
      font-size: 12px;
      color: $color-white;
    }
  }
}
